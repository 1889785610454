import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { batch, useDispatch } from 'react-redux';

import {
  getNutritionist,
  getNutritionistAppointmentSchedule,
  getNutritionistByParams,
  getNutritionists,
  getNutritionistTestOptions,
  getNutritionistTestSpecs,
  requestGetInTouch,
} from 'network/services/nutritionists.service';

import {
  setNutritionist,
  setNutritionistsCollection,
} from 'redux/slices/nutritionist.slice';
import { useSnackbar } from 'notistack';
import { setModalStatus } from '../redux/slices/modal.slice';
import { setNutritionistTestOptions } from '../redux/nutritionistTest/nutritionistTest.slice';
import { setMyCurrentNutritionistServices } from '../redux/slices/myCurrentNutritionist.slice';
import { useHistory } from 'react-router-dom';

// ---- TRAE LA INFORMACIÓN DE UN NUTRIOLOGO ----
export const useGetNutritionist = id => {
  const dispatch = useDispatch();

  return useQuery(['GET_NUTRITIONIST', id], () => getNutritionist(id), {
    enabled: !!id,
    onSuccess: data => {
      const online = data.nutritionistProfile.services.filter(service =>
        service.mode.includes('online'),
      );
      const site = data.nutritionistProfile.services.filter(service =>
        service.mode.includes('site'),
      );
      const home = data.nutritionistProfile.services.filter(service =>
        service.mode.includes('home'),
      );
      batch(() => {
        dispatch(
          setNutritionist({
            _id: data._id,
            name: data.name,
            offices: data.offices,
            services: {
              online,
              site,
              home,
            },
            completeList: [...online, ...site, ...home],
          }),
        );
        dispatch(
          setMyCurrentNutritionistServices(data.nutritionistProfile.services),
        );
      });
    },
  });
};

// ---- TRAE EL LISTADO DE NUTRIOLOGOS ----
export const useGetNutritionists = (page = 0) => {
  const dispatch = useDispatch();

  return useMutation('GET_NUTRITIONISTS', getNutritionists, {
    onSuccess: data => {
      dispatch(setNutritionistsCollection(data));
    },
  });
};

// ---- SOLICITA OBTENER INFORMACIÓN DEL NUTRIOLOGO ----
export const useRequestGetInTouch = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  return useMutation(requestGetInTouch, {
    onSuccess: () => {
      enqueueSnackbar(
        'Información enviada, pronto recibirás información de tu nutriólogo',
        {
          variant: 'success',
        },
      );
      replace('/dashboard/main');
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'GET_IN_TOUCH_NUTRITION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE LAS OPCIONES PARA REALIZAR EL TEST PARA ENCONTRAR NUTRIOLOGOS ----
export const useGetNutritionistTestOptions = () => {
  const dispatch = useDispatch();

  return useQuery('GET_NUTRITIONIST_TEST_OPTIONS', getNutritionistTestOptions, {
    enabled: false,
    onSuccess: data => {
      dispatch(setNutritionistTestOptions(data));
    },
  });
};

// ---- TRAE LAS ESPECIALIDADES PARA HACER LA BÚSQUEDA DE NUTRIIOLOGOS MEDIANTE TEST ----
export const useGetTestSpecialties = key => {
  return useQuery(
    'GET_NUTRITIONIST_TEST_SPECS',
    () => getNutritionistTestSpecs(key),
    {
      enabled: false,
    },
  );
};

// ---- TRAE LA LISTA DE NUTRIOLOGOS FILTRADOS POR ESPECIALIDADES ----
export const useGetFilteredNutritionists = params => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_FILTERED_NUTRITIONISTS',
    () => getNutritionistByParams(params),
    {
      enabled: false,
      onSuccess: data => {
        dispatch(setNutritionistsCollection(data));
      },
    },
  );
};

// ---- TRAE LAS FECHAS Y HORARIOS DISPONIBLES DE UN NUTRIOLOGO PARA AGENDAR UNA CITA ----
export const useGetNutritionistAppointmentSchedule = payload => {
  return useQuery(
    'GET_NUTRITIONIST_APPOINTMENT_SCHEDULE',
    () => getNutritionistAppointmentSchedule(payload),
    {
      enabled: false,
    },
  );
};
