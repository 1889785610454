import { wellnub } from '../wellnub.api';

export const getNutritionists = async page => {
  const { data } = await wellnub.get(
    `/v1/client/user/nutritionist?page=${page}&limit=10`,
  );
  return data;
};

export const getNutritionist = async id => {
  const { data } = await wellnub.get(`/v1/client/user/nutritionist/${id}`);
  return data;
};

export const requestGetInTouch = ({ id, payload, shouldLinkRequest }) =>
  wellnub.post(
    `/v1/client/contact/nutritionist/${id}?shouldLinkRequest=${shouldLinkRequest}`,
    payload,
  );

export const getNutritionistTestOptions = async () => {
  const { data } = await wellnub.get(`/v1/client/testFindNutritionist`);
  return data;
};

export const getNutritionistTestSpecs = async key => {
  const { data } = await wellnub.get(
    `/v1/client/testFindNutritionist?childsFrom=${key}`,
  );
  return data;
};

export const getNutritionistByParams = async params => {
  const { data } = await wellnub.get(`/v1/client/user/nutritionist${params}`);
  return data;
};

export const getNutritionistAppointmentSchedule = async payload => {
  const { data } = await wellnub.get(
    `/v1/client/user/nutritionist/service/schedule/${payload.nutritionistId}?serviceId=${payload.params.service}&serviceMode=${payload.params.mode}&timeZone=${payload.params.timeZone}`,
  );
  return data;
};
