import { useState, useCallback, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { makeStyles, Box } from '@material-ui/core';

import { Footer, Navbar } from 'components/organisms';

import { WellnubBackdrop } from '@wellnub/web-common';

import {
  Lives,
  Vwods,
  Coaches,
  Coach,
  SignUp,
  Live,
  Vwod,
  Dashboard,
  Nutritionist,
  Nutritionists,
  Home,
  Settings,
  VideoWorkout,
  SignInPage,
  PrivacyPage,
  ViewerPage,
  ConditionsPage,
  AccountInactivePage,
  Tutorials,
  PendingVerificationPage,
  EmailRetryPage,
  EmailValidationPage,
  PhoneValidationPage,
  SuccessfullyVerificationPage,
  EmailTokenExpiredPage,
  PhoneNumberRetryPage,
  ValidationCompletedPage,
  SuccessfullyVerificationPageByClient,
  MarketPage,
  PaymentSubscriptionsPage,
  NutritionistContactPage,
} from 'pages';

import ProtectedRoute from 'utils/protected/Protected.route';

import ZoomSession from 'containers/ZoomSession/Loadable';

import { DrawerManager, ModalManager, RaitingPanel } from 'components';

import { setUser } from 'redux/slices/user.slice';
import { setAuthentication } from 'redux/slices/authentication.slice';

import { withRedirect, withProtected } from 'utils/authentication';

const useStyles = makeStyles(() => ({
  app: {
    minHeight: props =>
      `calc(100vh - ${props.footerHeight}px - ${props.navbarHeight}px)`,
  },
}));

const App = () => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const { isOpen } = useSelector(state => state.backdrop);

  const dispatch = useDispatch();

  const classes = useStyles({ navbarHeight, footerHeight });

  const isAuthenticated = JSON.parse(localStorage.getItem('authentication'));

  const measuredNavbarRef = useCallback(node => {
    if (node !== null) {
      setNavbarHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const measuredFooterRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-XKQKLXE1XQ');
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    dispatch(setUser(user));
  }, [dispatch]);

  if (isAuthenticated) {
    dispatch(setAuthentication(true));
  }

  return (
    <Fragment>
      <WellnubBackdrop open={isOpen} Component={<RaitingPanel />} />
      <ModalManager />
      <DrawerManager />
      <Navbar ref={measuredNavbarRef} />
      <Box component="main" className={classes.app}>
        <Switch>
          <Route exact path="/" component={withRedirect(Home)} />

          <Route exact path="/signin" component={withRedirect(SignInPage)} />

          <Route exact path="/lives">
            <Lives />
          </Route>
          <Route exact path="/lives/:liveID">
            <Live />
          </Route>

          <Route exact path="/vwods">
            <Vwods />
          </Route>
          <Route exact path="/vwods/:vwodID">
            <Vwod />
          </Route>

          <ProtectedRoute exact path="/VWoD/:id">
            <VideoWorkout />
          </ProtectedRoute>

          <Route exact path="/coaches">
            <Coaches />
          </Route>
          <Route exact path="/coaches/:id">
            <Coach />
          </Route>

          <Route exact path="/signup" component={withRedirect(SignUp)} />

          <Route exact path="/user/liveMeeting/:meetingId">
            <ZoomSession />
          </Route>

          <Route path="/dashboard" component={withProtected(Dashboard)} />

          <Route exact path="/nutritionists" component={Nutritionists} />

          <Route
            exact
            path="/nutritionists/contact/link-request/:id"
            component={NutritionistContactPage}
          />

          <Route exact path="/nutritionists/:name/:id">
            <Nutritionist />
          </Route>

          <Route exact path="/market" component={withProtected(MarketPage)} />

          <Route
            exact
            path="/payment-subscriptions"
            component={withProtected(PaymentSubscriptionsPage)}
          />

          <Route path="/settings" component={withProtected(Settings)} />

          <Route path="/tutorials" component={Tutorials} />

          <Route exact path="/privacy">
            <PrivacyPage />
          </Route>

          <Route exact path="/conditions">
            <ConditionsPage />
          </Route>

          <Route exact path="/account-inactive">
            <AccountInactivePage />
          </Route>

          <Route exact path="/pending-verification">
            <PendingVerificationPage />
          </Route>

          <Route exact path="/emailValidation">
            <EmailValidationPage />
          </Route>

          <Route exact path="/phoneValidation">
            <PhoneValidationPage />
          </Route>

          <Route exact path="/emailRetry">
            <EmailRetryPage />
          </Route>

          <Route exact path="/phone-number-retry">
            <PhoneNumberRetryPage />
          </Route>

          <Route exact path="/email-token-expired">
            <EmailTokenExpiredPage />
          </Route>

          <Route exact path="/success-verification">
            <SuccessfullyVerificationPage />
          </Route>

          <Route exact path="/success-verification-by-client">
            <SuccessfullyVerificationPageByClient />
          </Route>

          <Route exact path="/validation-completed">
            <ValidationCompletedPage />
          </Route>

          <Route exact path="/viewer/:id">
            <ViewerPage />
          </Route>
        </Switch>
      </Box>
      <Footer ref={measuredFooterRef} />
    </Fragment>
  );
};

export default App;
